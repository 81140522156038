import React, { useEffect, useMemo, useState } from 'react'

import Grid from '@mui/material/Grid'
import MDBox from 'components/MDBox'
import DataTable from 'examples/Tables/DataTable'
import MDAddIcon from 'components/MDAddIcon/Index'
import { useDispatch, useSelector } from 'react-redux'
import * as Actions from '../../App/store/actions'
import { connect } from 'react-redux'
import MDSpinner from 'components/MDSpinner'
import DeleteModal from './DeleteModal/DeleteModal'
import MDButton from 'components/MDButton'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { Icon } from '@mui/material'
function Background(props) {
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const [loader, setLoader] = React.useState(false)
  const dispatch = useDispatch()
  const [img, setImg] = useState()

  let { backgroundList: { data: { data } = [] } = {} } = useSelector(
    (state) => state.backgroundlistReducer
  )

  useEffect(() => {
    dispatch(Actions.getBackgroundList((v) => setLoader(v)))
  }, [])

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: '#fff',
    border: '1px solid #fff',
    boxShadow: 24,
    p: 4,
  }

  const deleted = (id, status) => {
    dispatch(Actions.deleteBackground(id, status))
  }

  const columns = [
    { Header: 'Main gameplay', accessor: 'Maingameplay' },
    { Header: 'Action', accessor: 'action' },
  ]

  const rowsdata = data?.map((item, index) => ({
    Maingameplay: (
      <div
        onClick={() => handleImage(item.image_url)}
        style={{
          cursor: 'pointer',
          color: 'blue',
          textDecoration: 'underline',
        }}
      >
        {item.image_name}
      </div>
    ),
    action: (
      <DeleteModal  
        id={item.id}
        status={item.status === 'ACTIVE' ? 'DELETED' : 'ACTIVE'}
        deleted={deleted}
      />
    ),
  }))

  const handleImage = (imgs) => {
    handleOpen()
    // setLoader(true)
    setImg(imgs)
    // setTimeout(() => {
    //   setLoader(false)
    // }, 1000)
  }

  if (loader) {
    return <MDSpinner />
  }

  return (
    <>
      <MDBox pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <MDBox p={2}>
              {rowsdata && (
                <DataTable
                  table={{
                    columns: columns,
                    rows: rowsdata,
                  }}
                />
              )}
            </MDBox>
          </Grid>
          <MDAddIcon link="/add-background" color="inherit" font="sm" />
        </Grid>
      </MDBox>
      {open && (
        <Modal
          open={open}
          onClose={() => handleClose()}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <MDBox display="flex" justifyContent="space-between">
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Image Details
              </Typography>

              <MDBox>
                <Icon onClick={() => handleClose()} sx={{ cursor: 'pointer' }}>
                  close
                </Icon>
              </MDBox>
            </MDBox>
            <MDBox p={2}>
              <MDBox>
                <img className='back-img' src={img} width="100%"></img>
              </MDBox>
            </MDBox>
          </Box>
        </Modal>
      )}
    </>
  )
}
const mapDispatchToProps = {
  getBackgroundList: Actions.getBackgroundList,
  deleteBackground: Actions.deleteBackground,
}
const mapStateToProps = (state) => ({
  backgroundList: state.backgroundlistReducer.backgroundList,
  deleteData: state.backgroundlistReducer.deleteData,
})
export default connect(mapStateToProps, mapDispatchToProps)(Background)
